import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import CustomContext from '../../../context/CustomContext';
import { ReactComponent as eventsSvg } from '../../../resources/images/ico-events.svg';
import { ReactComponent as leaderboardSvg } from '../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as playerProfileSvg } from '../../../resources/images/ico-playerProfile.svg';
import { ReactComponent as collegeSvg } from '../../../resources/images/ico-collegeDirectory.svg';
import { ReactComponent as trainingSvg } from '../../../resources/images/ico-training.svg';
import ga from '../../../services/GoogleAnalytics';
import PlayerMenuComponent from '../Common/PlayerMenuComponent/PlayerMenuComponent';

class ParentNavigationComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /*** METHODS ***/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    callAnalyticsTrainingAppEvent = () => {
        ga.createDefaultEvent('training app', 'training app - redirect to training app page from menu');
    };

    /*** COMPONENTS ***/

    renderPlayerMenu = (): React.ReactNode | undefined => {
        const { collapsed, playerEditable } = this.props;
        const userId = this.props.userId;
        const sportId = this.props.sportId;
        const familyId = this.props.familyId;
        const organizationIds = this.props.organizationIds || [];

        if (userId && sportId) {
            return (
                <PlayerMenuComponent
                    userId={userId}
                    sportId={sportId}
                    familyId={familyId}
                    organizationIds={organizationIds}
                    divider={true}
                    editable={playerEditable}
                    collapsed={collapsed}
                />
            );
        }
    };

    renderParentMenu = (): React.ReactNode | undefined => {
        const { collapsed } = this.props;
        const userId = this.props.userId || this.context.auth?.id;
        const familyId = this.props.familyId || this.context.auth?.familyId;

        if (userId && familyId) {
            return (
                <Menu.ItemGroup
                    key="parent-dashboard"
                    title={!collapsed && <FormattedMessage id="navigation.parent.dashboard" />}
                >
                    <Menu.Item
                        key="parent-profile"
                        icon={<Icon component={playerProfileSvg} />}
                        onClick={() => this.navigate('')}
                    >
                        <Link to="">
                            <FormattedMessage id="navigation.parent.dashboard.profile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="leaderboard"
                        icon={<Icon component={leaderboardSvg} />}
                        onClick={() => this.navigate('/leaderboard')}
                    >
                        <Link to="/leaderboard">
                            <FormattedMessage id="navigation.parent.dashboard.leaderboard" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="events"
                        icon={<Icon component={eventsSvg} />}
                        onClick={() => this.navigate('/events')}
                    >
                        <Link to="/events">
                            <FormattedMessage id="navigation.parent.dashboard.events" />
                        </Link>
                    </Menu.Item>
                    {this.context.auth?.collegeConnectEnabled && (
                        <Menu.Item
                            key="colleges-public"
                            icon={<Icon component={collegeSvg} />}
                            onClick={() => this.navigate('/colleges')}
                        >
                            <Link to="/colleges">
                                <FormattedMessage id="navigation.organization.dashboard.colleges" />
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item
                        key="training"
                        icon={<Icon component={trainingSvg} />}
                        onClick={this.callAnalyticsTrainingAppEvent}
                    >
                        <a href="https://www.top100sports.com/training-app" target="_blank" rel="noreferrer">
                            <FormattedMessage id="navigation.player.dashboard.training" />
                        </a>
                    </Menu.Item>
                </Menu.ItemGroup>
            );
        }
    };

    render() {
        const { menu } = this.props;

        if (menu) {
            return menu;
        } else {
            return (
                <>
                    {this.renderPlayerMenu()}
                    {this.renderParentMenu()}
                </>
            );
        }
    }
}
export default injectIntl(withRouter(ParentNavigationComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    menu?: React.ReactNode;
    collapsed: boolean;
    playerEditable?: boolean;
    userId?: string;
    sportId?: number;
    familyId?: number;
    organizationIds?: string[];
}
